import React, { useEffect } from 'react';
import './Content.css'

const ContactPage: React.FunctionComponent<{}> = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <section>
                <ul>
                    <li>Marcelo Rovira Torres</li>
                    <li>dipl. architect EPFL</li>
                    <li>marcelo@rovira.se</li>
                    <li>+46 (0)709 24 37 60</li>
                </ul>
            </section>
        </div>
    );
}

export default ContactPage;
